var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',{attrs:{"title":"Posting Awal / Akhir Hari"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-date-picker',{staticStyle:{"width":"125px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY"},model:{value:(_vm.tglposting),callback:function ($$v) {_vm.tglposting=$$v},expression:"tglposting"}}),_c('a-button',{staticClass:"btn btn-outline-primary ml-2",on:{"click":_vm.postingamor}},[_vm._v("Posting")])],1)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-table',{staticClass:"mt-3",staticStyle:{"margin":"0 -40px","margin-bottom":"-25px"},attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":{
        hideOnSinglePage: true,
        defaultPageSize: 10000000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30'],
      },"size":"small","loading":_vm.loading},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
              .toString()
              .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"text-danger",on:{"click":function($event){return _vm.confirmationDelete(record.tgl)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])}},{key:"tgl",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text, 'YYYY-MM-DD'))+" ")])}},{key:"waktu_Posting",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text, 'YYYY-MM-DDTHH:mm:ss', 'DD-MM-YYYY HH mm ss'))+" ")])}},{key:"formatCurrency",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }